import { observer } from "mobx-react";
import { QuestionnaireLeadTypes } from "types/questionnaire-tool-page.type";
import { FC } from "react";
import { Box, HStack } from "@chakra-ui/react";
import { LeadTypeTag } from "components";
import { getLeadTypeLabel } from "utils/lead-type.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileSlash } from "@fortawesome/pro-solid-svg-icons";
import { QuestionnaireToolPageWIPFormStore } from "../stores/QuestionnaireToolPageWIPForm.store";

interface IProps {
  leadType: QuestionnaireLeadTypes;
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}

export const QuestionnaireToolPageSettingsQuestionnairesBranchingSelectedListSectionHeader: FC<IProps> = observer(
  ({ leadType, questionnaireToolPageWIPFormStore }) => {
    return (
      <HStack spacing={4}>
        <LeadTypeTag type={leadType} />
        <Box>
          Specify questions flow for{" "}
          <Box as={"b"} textTransform={"capitalize"}>
            {getLeadTypeLabel(leadType)}
          </Box>{" "}
          leads
        </Box>
        <Box
          color={"leadpro.400"}
        >{`${questionnaireToolPageWIPFormStore.wipFormSchemaJS.order.length} questions`}</Box>
        {questionnaireToolPageWIPFormStore.isIgnored && (
          <Box color={"leadpro.600"}>
            <FontAwesomeIcon icon={faFileSlash} /> Ignored
          </Box>
        )}
      </HStack>
    );
  }
);
