import {
  FormActionSendEmail,
  FormActionType,
  FormSchema,
  LeadTypes,
  validateFormSchema,
} from "@leadpro/forms";
import { TPageData } from "../types/page.type";
import { TFormToolSettingsFieldValues } from "./validation-schemas/page-settings.validation";
import { intersection, isEqual } from "lodash";
import { FormActionSendEmailRoutingTypes } from "@leadpro/forms";

export const generateFormToolPageDefaultFormSchema = (): FormSchema => {
  return {
    fields: {},
    order: [],
    version: "1.0",
  };
};

export const generateFormToolDefaultPageData = (
  page?: TPageData
): TFormToolSettingsFieldValues => {
  return {
    name: page?.name || "",
    host: page?.host || "",
    color: page?.color || "",
    facebookPixelId: page?.facebookPixelId || "",
    googleAnalyticsId: page?.googleAnalyticsId || "",
    googleAdsConversionId: page?.googleAdsConversionId || "",
    googleAdsConversionLabelId: page?.googleAdsConversionLabelId || "",
    privacyPolicyLink: page?.privacyPolicyLink || "",
    gdprCheckboxEnabled: page?.gdprCheckboxEnabled || false,
    gdprCheckboxLabel: page?.gdprCheckboxLabel || "",
    gdprCheckboxDescription: page?.gdprCheckboxDescription || "",
    gdprCheckboxAcceptanceRequired:
      page?.gdprCheckboxAcceptanceRequired || false,
    photoFile: null,
    customLogoFile: null,
    customThankYouPageHeader:
      page?.pageFormConfig.customThankYouPageHeader || "",
    customThankYouPageBody: page?.pageFormConfig.customThankYouPageBody || "",
    customThankYouPageCtaLabel:
      page?.pageFormConfig.customThankYouPageCtaLabel || "",
    customThankYouPageCtaUrl:
      page?.pageFormConfig.customThankYouPageCtaUrl || "",
  };
};

export const isActionParamFieldInError = (
  fieldName: string,
  actionIndex: number,
  validationResult: ReturnType<typeof validateFormSchema>
) => {
  const { errors } = validationResult;
  return !!errors?.find(
    error =>
      intersection(error.path, [actionIndex, "params", fieldName]).length === 3
  );
};

export const getErrorForConditionGroup = (
  validationPath: (string | number)[],
  validationResult: ReturnType<typeof validateFormSchema>
) => {
  const { errors } = validationResult;
  const foundError = errors?.find(error => isEqual(error.path, validationPath));
  return !!foundError ? foundError.message : null;
};

export const getErrorForConditionExpression = (
  validationPath: (string | number)[],
  validationResult: ReturnType<typeof validateFormSchema>
) => {
  const { errors } = validationResult;
  const foundError = errors?.find(error => {
    return isEqual(error.path.slice(0, -1), validationPath);
  });

  return !!foundError ? foundError.message : null;
};

export const generateDefaultSendEmailFormActionParamsByRoutingType = (
  routingType: FormActionSendEmailRoutingTypes = FormActionSendEmailRoutingTypes.OFFICE
) => {
  if (routingType === FormActionSendEmailRoutingTypes.OFFICE) {
    return {
      routingType: {
        value: FormActionSendEmailRoutingTypes.OFFICE,
      },
      postcode: { mapping: "" },
      officeEmailType: {
        value: LeadTypes.Let,
      },
    } as FormActionSendEmail["params"];
  }

  if (routingType === FormActionSendEmailRoutingTypes.DIRECT) {
    return {
      routingType: {
        value: FormActionSendEmailRoutingTypes.DIRECT,
      },
      emails: {
        value: [],
      },
    } as FormActionSendEmail["params"];
  }

  throw new Error("Routing type not recognized!");
};

export const generateDefaultCreateLeadFormActionParams = () => {
  return {
    leadType: { value: LeadTypes.Let },
    email: { mapping: "" },
    firstName: { mapping: "" },
    lastName: { mapping: "" },
    phone: { mapping: "" },
    postcode: { mapping: "" },
    address: { mapping: "" },
  };
};

export const generateFormActionStub: any = (actionType?: FormActionType) => {
  return {
    type: actionType || "",
    params: actionType ? defaultActionParamsGenerator[actionType]?.() : {},
  };
};

const defaultActionParamsGenerator: Record<FormActionType, () => any> = {
  [FormActionType.CREATE_LEAD]: generateDefaultCreateLeadFormActionParams,
  [FormActionType.SEND_EMAIL]: generateDefaultSendEmailFormActionParamsByRoutingType,
};
