import { ActionPromptContainer } from "components";
import { Box, Button, Divider, HStack } from "@chakra-ui/react";
import React, { FC, useCallback, useState } from "react";
import { QuestionnaireToolPageWIPFormStore } from "../../stores/QuestionnaireToolPageWIPForm.store";
import { observer } from "mobx-react";
import { LogicElementJumpConfigurator } from "./LogicElementJumpConfigurator/LogicElementJumpConfigurator";
import { FormLogicJump } from "@leadpro/forms/dist/types/form-logic";
import { FormLogicJumpOperatorEnum } from "enums/form-logic-jump-operator.enum";
import { Node } from "@reactflow/core/dist/esm/types/nodes";
import { TQuestionNodeData } from "types/questionnaire-branching.type";
import { QuestionnaireBranchingNodeTypes } from "enums/questionnaire-branching.enum";

interface IProps {
  fromNode: Node<TQuestionNodeData, QuestionnaireBranchingNodeTypes>;
  toNode: Node<TQuestionNodeData, QuestionnaireBranchingNodeTypes>;
  closePrompt: () => void;
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}

export const NewLogicElementPrompt: FC<IProps> = observer(
  ({ fromNode, toNode, closePrompt, questionnaireToolPageWIPFormStore }) => {
    const [formLogicJump, setFormLogicJump] = useState<FormLogicJump>({
      field: fromNode.id,
      to: toNode.id,
      operator: FormLogicJumpOperatorEnum.IS,
      value: "",
    });

    const handleConfirm = useCallback(() => {
      if (!formLogicJump) return;

      questionnaireToolPageWIPFormStore.addLogicElement(
        fromNode.id,
        formLogicJump
      );
      closePrompt();
    }, [
      closePrompt,
      formLogicJump,
      fromNode.id,
      questionnaireToolPageWIPFormStore,
    ]);

    const onChange = useCallback((newValue: FormLogicJump | null) => {
      if (!!newValue) {
        setFormLogicJump(newValue);
      }
    }, []);

    return (
      <ActionPromptContainer
        header={"Add a new logic element"}
        body={
          <Box>
            <LogicElementJumpConfigurator
              fromNode={fromNode}
              toNode={toNode}
              onChange={onChange}
              formLogicJump={formLogicJump}
            />
            <Divider mb={4} />
            <HStack width={"100%"} spacing={4} justify={"flex-end"}>
              <Button variant={"outline"} onClick={() => closePrompt()}>
                Cancel
              </Button>
              <Button
                type={"submit"}
                colorScheme={"blue"}
                onClick={handleConfirm}
              >
                Add
              </Button>
            </HStack>
          </Box>
        }
      />
    );
  }
);
