import { AccountReapitApi } from "api/account-reapit.api";
import { IObservableArray, makeAutoObservable, observable } from "mobx";
import { AppStore } from "store/App.store";
import { TReapitArea } from "types/reapit.type";

const accountReapitApi = new AccountReapitApi();

export class AccountIntegrationReapitAreaStore {
  private readonly root: AppStore;
  private readonly accountId: number;
  private accountAreas: IObservableArray<TReapitArea>;

  constructor(root: AppStore, accountId: number) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.root = root;
    this.accountId = accountId;
    this.accountAreas = observable.array<TReapitArea>();
  }

  get reapitAccountAreas() {
    return this.accountAreas;
  }

  get reapitAccountAreasOptions() {
    return this.accountAreas
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(area => ({
        value: area.id,
        label: `${area.name} (${area.id})`,
      }));
  }

  private async setReapitAccountAreas(accountAreas: TReapitArea[]) {
    this.accountAreas.replace(accountAreas);
  }

  public async fetchReapitAccountAreas(leadId: number, officeGroupId?: number) {
    const data = await accountReapitApi.fetchAccountReapitAreas(
      this.accountId,
      leadId,
      officeGroupId
    );

    await this.setReapitAccountAreas(data);
  }
}
