import { MarkerType } from "reactflow";
import { QuestionnaireBranchingEdgeTypes } from "../enums/questionnaire-branching.enum";
import { FormLogicJumpOperatorEnum } from "../enums/form-logic-jump-operator.enum";
import { TSelectOption } from "../types/select-input.type";
import {
  FormLogicJump,
  FormLogicJumpAlways,
} from "@leadpro/forms/dist/types/form-logic";

export const COMMON_EDGE_SETTINGS = {
  type: QuestionnaireBranchingEdgeTypes.JUMP_EDGE,
  animated: true,
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 20,
    height: 20,
    color: "#3B68AA", // leadpro.600
  },
  style: {
    strokeWidth: 2,
    stroke: "#3B68AA", // leadpro.600
  },
};

export const OPERATOR_LABEL: Record<FormLogicJumpOperatorEnum, string> = {
  [FormLogicJumpOperatorEnum.IS]: "With",
  [FormLogicJumpOperatorEnum.ALWAYS]: "Always",
};

export const OPERATOR_OPTIONS: TSelectOption<FormLogicJumpOperatorEnum>[] = [
  {
    label: OPERATOR_LABEL[FormLogicJumpOperatorEnum.IS],
    value: FormLogicJumpOperatorEnum.IS,
  },
  {
    label: OPERATOR_LABEL[FormLogicJumpOperatorEnum.ALWAYS],
    value: FormLogicJumpOperatorEnum.ALWAYS,
  },
];

export const DEFAULT_JUMP_DATA: Record<
  FormLogicJumpOperatorEnum,
  (from: string, to: string) => FormLogicJump
> = {
  [FormLogicJumpOperatorEnum.ALWAYS]: (from: string, to: string) =>
    ({
      to: to,
      operator: FormLogicJumpOperatorEnum.ALWAYS,
    } as FormLogicJumpAlways),
  [FormLogicJumpOperatorEnum.IS]: (from: string, to: string) => {
    return {
      field: from,
      to: to,
      operator: FormLogicJumpOperatorEnum.IS,
      value: "",
    };
  },
};

export const QUESTIONNAIRE_BRANCHING_END_NODE = "$end";
