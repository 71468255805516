import { observer } from "mobx-react";
import React, { FC, useCallback, useMemo, useState } from "react";
import { QuestionnaireToolPageWIPFormStore } from "../../stores/QuestionnaireToolPageWIPForm.store";
import { Box, Button, Center, Divider, HStack, VStack } from "@chakra-ui/react";
import { ActionPromptContainer } from "components";
import { LogicElementJumpConfigurator } from "./LogicElementJumpConfigurator/LogicElementJumpConfigurator";
import { FormLogicJump } from "@leadpro/forms/dist/types/form-logic";
import { Node } from "@reactflow/core/dist/esm/types/nodes";
import { TQuestionNodeData } from "types/questionnaire-branching.type";
import { QuestionnaireBranchingNodeTypes } from "enums/questionnaire-branching.enum";

interface IProps {
  fromNode: Node<TQuestionNodeData, QuestionnaireBranchingNodeTypes>;
  toNode: Node<TQuestionNodeData, QuestionnaireBranchingNodeTypes>;
  closePrompt: () => void;
  questionnaireToolPageWIPFormStore: QuestionnaireToolPageWIPFormStore;
}

export const EditLogicElementJumpsPrompt: FC<IProps> = observer(
  ({ fromNode, toNode, closePrompt, questionnaireToolPageWIPFormStore }) => {
    const logicElement =
      questionnaireToolPageWIPFormStore.logicElementsMappedByField[fromNode.id];
    const [localJumps, setLocalJumps] = useState(logicElement.jumps);

    const onChange = useCallback(
      index => (newValue: FormLogicJump | null) => {
        if (!localJumps[index]) return;
        if (!newValue) {
          setLocalJumps(
            localJumps.filter((jump, jmpIndex) => jmpIndex !== index)
          );
        } else {
          const newLocalJumps = [...localJumps];
          newLocalJumps[index] = newValue;
          setLocalJumps(newLocalJumps);
        }
      },
      [localJumps]
    );

    const handleConfirm = useCallback(() => {
      questionnaireToolPageWIPFormStore.setLogicElementJumpsForField(
        fromNode.id,
        localJumps
      );
      closePrompt();
    }, [
      fromNode.id,
      localJumps,
      questionnaireToolPageWIPFormStore,
      closePrompt,
    ]);

    const jumpsToTotalCount = useMemo(
      () => localJumps.filter(jump => jump.to === toNode.id),
      [localJumps, toNode.id]
    ).length;

    return (
      <ActionPromptContainer
        header={"Edit jumps"}
        body={
          <Box>
            <VStack spacing={10}>
              {localJumps.map((jump, index) => {
                if (jump.to !== toNode.id) return undefined;
                return (
                  <Box
                    key={index}
                    width={"100%"}
                    borderLeft={"1px solid"}
                    borderColor={"leadpro.200"}
                    pl={4}
                  >
                    <LogicElementJumpConfigurator
                      fromNode={fromNode}
                      toNode={toNode}
                      formLogicJump={jump}
                      onChange={onChange(index)}
                      isRemovable={true}
                    />
                  </Box>
                );
              })}
              {!jumpsToTotalCount && (
                <Center height={"200px"} color={"leadpro.400"}>
                  <Box>No Jumps</Box>
                </Center>
              )}
            </VStack>
            <Divider mt={10} mb={4} />
            <HStack width={"100%"} spacing={4} justify={"flex-end"}>
              <Button variant={"outline"} onClick={() => closePrompt()}>
                Cancel
              </Button>
              <Button
                type={"submit"}
                colorScheme={"blue"}
                onClick={handleConfirm}
              >
                Update
              </Button>
            </HStack>
          </Box>
        }
      />
    );
  }
);
